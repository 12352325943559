@font-face {
  font-family: 'Graphik LCG';
  src: url('./../assets/fonts/GraphikLCG/GraphikLCG-Regular/GraphikLCG-Regular.eot');
  src: url('./../assets/fonts/GraphikLCG/GraphikLCG-Regular/GraphikLCG-Regular.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/GraphikLCG/GraphikLCG-Regular/GraphikLCG-Regular.woff2') format('woff2'),
  url('./../assets/fonts/GraphikLCG/GraphikLCG-Regular/GraphikLCG-Regular.woff') format('woff'),
  url('./../assets/fonts/GraphikLCG/GraphikLCG-Regular/GraphikLCG-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Graphik LCG';
  src: url('./../assets/fonts/GraphikLCG/GraphikLCG-Medium/GraphikLCG-Medium.eot');
  src: url('./../assets/fonts/GraphikLCG/GraphikLCG-Medium/GraphikLCG-Medium.eot?#iefix') format('embedded-opentype'),
  url('./../assets/fonts/GraphikLCG/GraphikLCG-Medium/GraphikLCG-Medium.woff2') format('woff2'),
  url('./../assets/fonts/GraphikLCG/GraphikLCG-Medium/GraphikLCG-Medium.woff') format('woff'),
  url('./../assets/fonts/GraphikLCG/GraphikLCG-Medium/GraphikLCG-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
