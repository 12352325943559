@tailwind base;
@tailwind components;
@tailwind utilities;

@import './@vex/styles/tailwind';
@import 'simplebar/dist/simplebar.css';
@import './@vex/styles/core.scss';
@import './scss/ipnote-theme';
@import './scss/var';
@import './scss/fonts';

html,
body {
  font-family: 'Graphik LCG', sans-serif;
  font-weight: 400;
}
.mtx-tooltip {
  max-width: unset !important;
}

.mat-dialog-container {
  position: relative;
  color: var(--color-dark-spell) !important;
}

.mat-list-item {
  font-family: var(--font-family-primary);
}

app-collaborators {
  height: 100%;
}

.bg-app-bar {
  background: var(--color-big-stone) !important;
}

input {
  font-family: var(--font-family-primary) !important;
  letter-spacing: 0.25px !important;
}

vex-sidenav-item {
  .item {
    min-height: auto !important;
    margin-bottom: 5px;
    padding: 8px !important;
    border: 1px solid transparent;
    color: var(--color-sailor-boy) !important;

    &-icon,
    &-dropdown-icon {
      color: var(--color-greek-flag-blue) !important;
    }

    &.active {
      background: var(--color-aeronautic) !important;
      border-color: var(--color-dark-spell) !important;
      border-radius: 4px;

      .item-label {
        color: var(--color-mercury) !important;
      }

      .item-icon,
      .item-dropdown-icon {
        color: var(--color-white) !important;
      }
    }

    &:hover {
      border-radius: 4px;

      .item-label {
        color: var(--color-mercury) !important;
      }

      .item-icon,
      .item-dropdown-icon {
        color: var(--color-white) !important;
      }
    }

    mat-icon.item-icon {
      margin-right: 10px;
    }
  }

  div.item {
    border-color: transparent !important;

    &.active {
      border-color: transparent !important;
      border-radius: 4px;

      &.open {
        border-radius: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }

    &-dropdown {
      background: var(--color-aeronautic) !important;
      position: relative;
      top: -10px;
      border-radius: 0 !important;
      border-bottom-left-radius: 4px !important;
      border-bottom-right-radius: 4px !important;

      .item {
        margin-bottom: 0 !important;
      }

      vex-sidenav-item:last-child {
        a {
          margin-bottom: 0 !important;
          border-radius: 4px !important;
        }
      }

      &.open {
        border-radius: 0 !important;
      }

      vex-sidenav-item {
        .item {
          background: var(--color-aeronautic) !important;

          &.active {
            background: var(--color-dana) !important;
            border-radius: 0;

            .item-label {
              color: var(--color-white) !important;
            }
          }
        }
      }
    }
  }
}

.mat-column-actions {
  text-align: center !important;
}

.table-container tr.hover\:bg-hover:hover {
  background-color: var(--color-hint-of-red) !important;
}

.mat-tab-links {
  a {
    font-family: var(--font-family-primary);
    font-weight: 500 !important;
    letter-spacing: 1.25px !important;
    text-transform: uppercase !important;
    color: var(--color-alabaster) !important;
    font-size: 12px !important;
    opacity: 1 !important;
    padding: 0 20px !important;
    min-width: 140px !important;
    height: 42px;

    &:hover {
      color: var(--color-havelock-blue) !important;
    }

    &.mat-tab-label-active {
      color: var(--color-havelock-blue) !important;
    }

    &.mat-tab-disabled {
      color: var(--color-midnight-green) !important;
    }
  }
}

.mat-ink-bar {
  height: 4px !important;
  background-color: var(--color-havelock-blue) !important;
}

mat-paginator {
  background-color: var(--color-white) !important;
  font-size: 14px !important;
  //height: var(--ip-paginator-height);
  .mat-form-field-wrapper {
    padding: unset;
  }

  .mat-paginator-range-actions {
    padding-bottom: unset;
  }

  .mat-paginator-page-size-select {
    margin: 4px 4px 4px 4px;
  }

  color: var(--color-dark-spell) !important;
  font-family: var(--font-family-primary) !important;
  border-top: 1px solid #edf1f7;

  .mat-paginator,
  .mat-paginator-page-size .mat-select-trigger {
    font-family: var(--font-family-primary) !important;
  }
  .mat-paginator-page-size {
    @media (max-width: 600px) {
      display: none;
    }
  }

  .mat-form-field-underline {
    display: none !important;
  }

  .mat-paginator-container {
    padding: 0 16px !important;
    justify-content: flex-start !important;
    min-height: 40px !important;
  }

  .mat-icon-button {
    color: var(--color-dana);
  }
}

mat-sidenav.option-sidenav {
  width: 401px;
}

.add-button {
  position: absolute;
  left: auto !important;
  bottom: 30px !important;
  z-index: 100;
  right: 30px;
}

.entity-settings {
  padding: 25px 30px;
  display: flex;
  justify-content: space-between;
  background: var(--color-white);
  align-items: center;

  .entity-search {
    flex: 0 371px;
    max-width: var(--entity-search-width);
    width: 100%;
  }

  .entity-filters {
    flex: 0 0 100px;

    .mat-badge-content {
      font-size: 10px;
      line-height: 15px;
      letter-spacing: -0.4px;
      width: 17px;
      top: -4px;
      height: 17px;
      right: -4px !important;
      z-index: 99;
    }

    .mat-icon-button {
      line-height: 32px;
      width: 32px;
      height: 32px;
    }

    .all-tasks {
      font-size: 14px;
      padding-top: 5px;
      font-weight: 500;
      margin-right: 15px;
    }
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: var(--color-sailor-boy) !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: var(--color-sailor-boy) !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start {
  border-width: 1px !important;
}

form {
  mat-form-field {
    color: var(--color-black-pearl);
  }
}

.common-table {
  padding: 25px 0 32px;

  app-entity-tasks,
  app-history {
    padding: 0;
  }

  table {
    width: 100%;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: var(--color-dark-spell);
    border-right: 0 !important;

    tr {
      th {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        border-top: 0 !important;
        border-bottom: 2px solid #edf1f7 !important;
      }

      td {
        border-bottom: 1px solid #edf1f7 !important;
      }
    }
  }
}

.mat-form-field:not(.mat-form-field-appearance-legacy)
  .mat-form-field-prefix
  .mat-icon-button
  .mat-datepicker-toggle-default-icon,
.mat-form-field:not(.mat-form-field-appearance-legacy)
  .mat-form-field-suffix
  .mat-icon-button
  .mat-datepicker-toggle-default-icon {
  color: var(--color-tory-blue) !important;
}

mat-chip {
  background: var(--color-iceberg) !important;

  button {
    color: var(--color-black-pearl) !important;
    opacity: 0.87 !important;
  }

  mat-icon {
    color: var(--color-dana) !important;
    opacity: 0.34 !important;
  }
}

.filter-button,
.settings-button {
  margin: 0 5px !important;
}

.table-container table {
  thead {
    th {
      border-top: 1px solid #edf1f7 !important;
      border-bottom: 1px solid #edf1f7 !important;
      color: var(--color-midnight-green) !important;
      padding: 0 10px !important;

      &:last-child {
        padding-left: 12px !important;
        padding-right: 24px !important;
      }
    }
  }

  tbody {
    td {
      color: var(--color-dark-spell) !important;
    }
  }

  .mat-column-checkbox {
    padding: 0 24px 0 32px !important;
    border-right: 1px solid #edf1f7;
  }
}

.mat-checkbox-frame {
  border-color: var(--color-iceberg) !important;
}

.mat-column-select {
  overflow: initial;
}

.cdk-overlay-pane {
  .mat-menu-content {
    padding-top: 7px !important;
    padding-bottom: 7px !important;

    button {
      padding-left: 30px;
      padding-right: 45px;

      &.icon-btn {
        font-family: 'Graphik LCG', serif !important;
        padding-left: 45px;
        padding-right: 60px;

        &.active {
          span {
            color: #145eab !important;
          }
        }

        .mat-icon {
          &.check {
            position: absolute;
            left: 11px;
            top: 12px;
            color: #145fac !important;
          }

          &.direction-sort {
            position: absolute;
            right: 5px;
            font-size: 13px;
            top: 17px;
            margin-right: 0;
          }
        }
      }
    }
  }

  &.taskDetails {
    max-height: unset !important;
  }
}

.mat-menu-panel {
  max-width: 350px !important;
}

.mat-menu-item {
  &:hover:not([disabled]) {
    background: var(--color-boysenberry-shadow) !important;

    .mat-icon {
      color: var(--color-midnight-green) !important;
    }
  }

  .mat-icon {
    color: var(--color-sailor-boy) !important;
  }

  span {
    color: var(--color-dark-spell) !important;
  }
}

.actions-header {
  text-align: center !important;
}

mat-chip-list,
.mat-chip-list-wrapper,
.mat-chip-list-wrapper > input {
  outline: 0;
}

.filter-sidenav {
  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: var(--color-sailor-boy) !important;
  }

  mat-label {
    color: var(--color-dana) !important;
  }

  .mat-select-arrow {
    color: var(--color-dana);
  }

  .mat-form-field-wrapper {
    padding-bottom: 15px !important;
  }
}

nav.mat-tab-nav-bar.mat-tab-header {
  background: var(--color-big-stone);
  border: 0;
  .mat-tab-header-pagination-chevron {
    border-color: white;
  }
}

app-links-chips {
  mat-form-field {
    .mat-form-field-outline,
    .mat-form-field-outline-start,
    .mat-form-field-outline-gap,
    .mat-form-field-outline-end,
    .mat-form-field-outline.mat-form-field-outline-thick {
      color: transparent !important;
    }

    .mat-form-field-label {
      display: none !important;
    }

    .mat-form-field-infix {
      padding: 0 !important;
    }
  }
}

.mat-select,
mat-checkbox {
  font-family: var(--font-family-primary) !important;
}

.filter-relations {
  app-links-chips {
    mat-form-field {
      .mat-form-field-wrapper {
        padding-bottom: 0 !important;
      }

      .mat-form-field-outline,
      .mat-form-field-outline-start,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-end,
      .mat-form-field-outline.mat-form-field-outline-thick {
        color: transparent !important;
      }

      .mat-form-field-label {
        display: none !important;
      }

      .mat-form-field-infix {
        padding: 0 !important;
      }
    }
  }
}

.mat-sidenav-content {
  main.content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.balance-field .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.show-all-links:hover {
  background: var(--color-iceberg);
}

td.mat-cell a span {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: var(--color-black);
}

.mat-tab-header {
  box-shadow: var(--elevation-z1);
}

.flag {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 7px;
}

.muted {
  color: var(--color-alto);
  margin-top: 10px;
  display: inline-block;
}

@media (max-width: 767px) {
  .cdk-overlay-pane#cdk-overlay-1,
  .cdk-overlay-pane#cdk-overlay-0 {
    min-width: 300px !important;
  }
}

th,
td,
tr {
  box-sizing: border-box !important;
}

th.mat-header-cell {
  padding-right: 5px;

  .sort-header-title {
    font-size: 12px;
    line-height: 15px;
    display: inline-block;
  }

  .resize-holder {
    position: absolute;
    right: 0;
    top: 5px;
    bottom: 5px;
    width: 5px;
    cursor: col-resize;
    border-right: 1px solid;
    border-right-color: var(--foreground-divider);
  }
}

table.mat-table {
  min-width: 100% !important;

  td.mat-cell {
    white-space: pre-line;
    color: var(--color-dark-spell);
    font-family: var(--font-family-primary);
  }
}

app-intelligence-property-tasks {
  table.mat-table {
    td.mat-cell {
      white-space: nowrap;
    }
  }
}

.fix-height {
  min-height: 300px;
}

.dropdown-panel {
  min-width: 400px;
  //max-height: 300px;
  padding: 24px;
  border-radius: 3px;
  box-shadow: 0 1px 4px 0 var(--color-dropdown);
  display: block;
  background: var(--color-white);

  .simplebar-scrollbar:before {
    background: var(--color-iceberg) !important;
  }

  .dropdown-search {
    background: var(--color-iceberg);
    opacity: 0.4;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0px 20px;
  }

  .input {
    flex: 1 1;
    background: var(--color-iceberg);
    border: none;
    padding: 10px 20px;
    outline: none;
    max-width: 100%;
    box-sizing: border-box;
  }

  .links-item {
    padding: 10px 20px;
    font-weight: 500;
    letter-spacing: 0.1px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.table-container {
  .simplebar-scrollbar:before {
    background: var(--color-heather);
  }
}

.simplebar-offset {
  margin-top: -1px !important;
}

.table-container {
  border-top: 1px solid #edf1f7 !important;

  th {
    button {
      font-family: var(--font-family-primary) !important;
    }
  }

  table {
    font-family: var(--font-family-primary) !important;
  }
}

.mat-sort-header-arrow {
  color: var(--color-iceberg);
}

.mat-column-authors,
.mat-column-rightholders {
  position: relative;
}

.list-all-chips {
  background: var(--color-white);
  border: 1px solid var(--color-iceberg);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07), 0px 4px 5px rgba(0, 0, 0, 0.06), 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 500px;
  position: absolute;
  top: 80px;
  z-index: 999;
  padding: 12px 15px 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.empty-search {
  padding: 15px 30px;
  letter-spacing: 0.25px;
  color: var(--color-black-pearl);
}

.mat-column-actions {
  z-index: 99 !important;
}

.simplebar-track.simplebar-horizontal {
  bottom: -2px;
}

.mat-drawer {
  overflow: initial !important;
}

mat-sidenav {
  .simplebar-scrollbar:before {
    background: var(--color-heather);
  }
}

.mat-form-field-label:not(.mat-form-field-empty) {
  line-height: 15px !important;
  font-family: var(--font-family-primary) !important;
}

.item-dropdown {
  font-size: 12px;
  font-family: var(--font-family-secondary);
  line-height: 15px;
  font-weight: 500;
}

.mat-sort-header-button {
  text-align: left;
}

.cards-container .simplebar-scrollbar:before {
  background: var(--color-heather);
}

.mat-menu-panel {
  max-width: 100% !important;
}

.border-overlay {
  border: solid var(--color-havelock-blue) 3px;
  border-radius: 5px;
}

.image-icon {
  width: 24px;
  height: 24px;
}

.d-flex-row-center {
  display: flex;
  align-items: center;
}

.overflow-scroll {
  overflow-y: scroll;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.inner-message a {
  color: #2b5da2;
  text-decoration-line: underline;
}

.mat-button {
  color: #8997b1;
  font-family: 'Graphik LCG', serif;
  font-size: 12px;
  font-weight: 500 !important;
  letter-spacing: 1.25px;
  border-radius: 2px !important;
  text-transform: uppercase;
  // padding: 0 20px;
  &.mat-button-disabled {
    color: #757575 !important;
  }
}

.mat-raised-button,
.mat-flat-button {
  font-family: 'Graphik LCG', serif;
  font-size: 12px;
  font-weight: 500 !important;
  letter-spacing: 1.25px;
  border-radius: 2px !important;
  text-transform: uppercase;
  //padding: 0 20px;
  &.mat-primary,
  &.mat-accent {
    color: #ffffff !important;
  }
  &.mat-button-disabled {
    background-color: #D1D7E0 !important;
    color: #ffffff !important;
  }

  &.mat-green {
    background-color: var(--color-sushi);
    color: white;
  }
}

.mat-stroked-button {
  color: var(--color-tory-blue);
  font-family: 'Graphik LCG', serif;
  font-size: 12px;
  font-weight: 500 !important;
  letter-spacing: 1.25px;
  border-radius: 2px !important;
  border: solid var(--color-greek-flag-blue) 2px !important;
  text-transform: uppercase;
  &.mat-button-disabled {
    color: var(--color-sailor-boy) !important;
    border: var(--color-sailor-boy) solid 2px !important;
  }
}

a.link-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.cdk-overlay-pane {
  @media (max-width: 999px) {
    transform: translateX(0px) !important;
  }
}

.wrap-text-2-lines {
  word-break: break-word;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.mat-paginator-range-actions {
  padding-bottom: 12px;

  button {
    margin-bottom: 4px;
  }
}

.mat-optgroup-label {
  height: inherit !important;
}

mat-option {
  height: auto !important;

  .mat-option-text {
    white-space: break-spaces;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.25px;
    padding-bottom: 12px;
    padding-top: 12px;
  }
}

.arrow-position {
  position: absolute;
  top: 16px;
  right: 0;
}

.multiline-tooltip {
  white-space: pre-line !important;
}

.mat-select.chips .mat-select-value {
  transform: translateY(-10px);

  .mat-chip-list-wrapper {
    flex-wrap: nowrap;

    mat-chip span {
      transform: translateY(2px);
      font-weight: normal;
    }
  }
}

.no-auto-margin-bottom {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}

mat-chip.small {
  font-size: 11px;
  font-weight: normal;

  mat-icon {
    color: #8997b1;
    font-size: 18px;
    height: auto;
  }

  span {
    color: #8997b1;
  }
}

.btn-icon__spinner {
  mat-spinner {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.mat-dialog-title {
  font-family: var(--font-family-primary);
}

.three-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-scroll::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  height: var(--blink-scrollbar-width);
  width: var(--blink-scrollbar-width);
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f8fafc;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  border-radius: var(--blink-scrollbar-width);
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.37);
}

.custom-scroll::-webkit-scrollbar-thumb:active {
  border-radius: var(--blink-scrollbar-width);
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  border-radius: var(--blink-scrollbar-width);
  box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.54);
}

.icon-tooltip {
  color: #afbdd1;
  //position: absolute;
  //top: 6px;
  //right: 30px;

  &:hover {
    color: #145eab;
    cursor: pointer;
  }
}

.link {
  color: #145eab;
}

.modal-close-icon {
  position: absolute;
  top: -20px;
  right: -20px;
  color: gray;

  &:hover {
    cursor: pointer;
    color: black;
  }
}

.is-highlighted,
.is-highlighted-mobile {
  .rating,
  .rating-count,
  .completed-tasks {
    font-weight: 700;
  }
}

app-tasks, ipnote-intelligence-property-tasks-wrapper {
  .simplebar-content {
    padding: 0!important;
  }

  .simplebar-wrapper {
    box-shadow: 0 -1px 0 0 var(--color-aircraft-white)!important;
  }

  .simplebar-offset {
    margin-top: -2px !important;
  }

  mat-paginator {
    .mat-form-field-appearance-outline .mat-form-field-outline {
      position: absolute;
      top: 9px;
      left: 0;
      right: 0;
      bottom: 7px;
    }

    .mat-form-field-appearance-outline .mat-form-field-infix {
      padding: 0 0 10px 0;
    }
  }

  .mat-select-arrow {
    margin-top: 8px;
  }

  mat-paginator .mat-paginator-page-size-select{
    margin: 0;
  }

  .mat-paginator-page-size {
    align-items: center;
  }
}

app-tasks-filters, app-filter-sidenav {
  .mat-form-field {
    margin-bottom: 0;
  }
}
