.card {
  @apply rounded bg-card;
  max-width: 26rem;
  box-shadow: 0px 0px 20px rgba(43, 93, 162, 0.08), 0px 0px 10px rgba(43, 93, 162, 0.06), 0px 0px 8px rgba(43, 93, 162, 0.08),
  inset -4px -4px 4px rgba(43, 93, 162, 0.12), 3px 3px 3px rgba(255, 255, 255, 0.2);
}

.avatar {
  @apply h-10 w-10 object-cover rounded-full bg-hover;
}

.hidden-input {
  @apply border-2 border-solid border-transparent py-1 px-3 rounded;

  &:focus {
    @apply border-primary-500 outline-none;
  }

  &.empty {
    @apply border-primary-500;
  }
}

.list-item {
  @apply rounded h-12 px-4 cursor-pointer;
}

.list-item:hover {
  @apply bg-hover;
}

.bg-pattern {
  background: url('../../assets/svg/Vector.svg') right 22.29% top 36% no-repeat,
  url('../../assets/svg/Vector360.svg') no-repeat left 22.29% bottom 36% #f3f4f7;
}

// Transitions

.trans-ease-out {
  transition: var(--trans-ease-out);
}

.trans-shadow {
  transition: var(--trans-shadow);
}

// Typography

.display-4 {
  font: var(--font-display-4);
}

.display-3 {
  font: var(--font-display-3);
}

.display-2 {
  font: var(--font-display-2);
}

.display-1,
h1 {
  font: var(--font-display-1);
}

.headline,
h2 {
  font: var(--font-headline);
}

.title,
h3 {
  font: var(--font-title);
}

.subheading-2 {
  font: var(--font-subheading-2);
}

.subheading-1 {
  font: var(--font-subheading-1);
}

.body-2 {
  font: var(--font-body-2);
}

.body-1 {
  font: var(--font-body-1);
}

.caption {
  font: var(--font-caption);
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

.blur {
  filter: blur(8px);

  &-sm {
    filter: blur(4px);
  }
}
