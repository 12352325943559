:root {
  --ip-toolbar-height: 61px;
  --ip-paginator-height: 56px;
  --filter-buttons-width: 350px;
  --ip-sidenav-color: #afbdd1;
  --ip-filter-sidenav: 400px;
  --entity-search-width: 380px;
  --filter-button-width: 120px;
  --font-family-primary: 'Graphik LCG', sans-serif;
  --font-family-secondary: 'Graphik LCG', sans-serif;
  --color-big-stone: #141c31;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-sailor-boy: #afbdd1;
  --color-greek-flag-blue: #145fac;
  --color-aeronautic: #2b3648;
  --color-dana: #56657f;
  --color-dark-spell: #313c4e;
  --color-maximum-red: #dc1a1b;
  --color-wash-me: #fafbfe;
  --color-aircraft-white: #edf1f7;
  --color-midnight-green: #8997b1;
  --color-boysenberry-shadow: #F8FAFC;
  --color-iceberg: #dae2ee;
  --color-light-gray: #F3F4F7;
  --color-black-pearl: #1e242e;
  --color-havelock-blue: #61aae1;
  --color-alabaster: #fafafa;
  --color-alice-blue: #f0f9ff;
  --color-black-rose: #690428;
  --color-indochine: #c66909;
  --color-la-palma: #469012;
  --color-tory-blue: #145eab;
  --color-torea-bay: #0f3f92;
  --color-deep-blue: #365D9D;
  --color-pomegranate: #f44336;
  --color-sushi: #77bb38;
  --color-claret: #701328;
  --color-boston-blue: #3c83c5;
  --color-tulip-tree: #f0a93b;
  --color-cornflower-blue: #c7e9f9;
  --color-hint-of-red: #f8f9fc;
  --color-golden-bell: #e7850d;
  --color-table-cell: rgba(218, 226, 238, 0.4);
  --color-alto: #d0d0d0;
  --color-dropdown: rgba(0, 0, 0, 0.24);
  --color-heather: #b6beca;
  --color-gallery: #efefef;
  --color-silver-chalice: #9e9e9e;
  --color-jumbo: #818182;
  --color-barley-white: #fff3cd;
  --color-yukon-gold: #856404;
  --color-dirty-white: #fefefe;
  --color-zanah: #d4edda;
  --color-parsley: #155724;
  --color-cinnabar: #e53935;
  --color-tamarillo: #970f24;
  --color-negroni: #fddec9;
  --color-orinoco: #e8f9c6;
  --color-beeswax: #fdf0c6;
  --color-hit-gray: #a8b2b9;
  --color-mine-shaft: #333333;
  --color-mercury: #e5e5e5;
  --color-silver-sand: #C6C6C6;
  --color-dark-spell-for-rgb: 49, 60, 78;
  --color-tory-blue-for-rgb: 20, 94, 171;
  --color-green-new-profile: #18965D;
  --color-light-gray-new-profile: #F3F7F5;
  --color-big-stone-new-profile: #0F1520;
  --color-cool-gray-new-profile: #6E798C;
  --color-gray-shadow-new-profile-rgb: rgba(15, 22, 32, 0.1);
  --color-blue-chat: #dff5ff;
}
